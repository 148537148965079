@font-face {
    font-family: 'Charity Miles';
    src: local('Charity Miles Bold'), local('CharityMiles-Bold'),
    url('../fonts/CharityMiles-Bold.woff2') format('woff2'),
    url('../fonts/CharityMiles-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Charity Miles';
    src: local('Charity Miles Light'), local('CharityMiles-Light'),
    url('../fonts/CharityMiles-Light.woff2') format('woff2'),
    url('../fonts/CharityMiles-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Charity Miles';
    src: local('Charity Miles Medium'), local('CharityMiles-Medium'),
    url('../fonts/CharityMiles-Medium.woff2') format('woff2'),
    url('../fonts/CharityMiles-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Charity Miles';
    src: local('Charity Miles Regular'), local('CharityMiles-Regular'),
    url('../fonts/CharityMiles-Regular.woff2') format('woff2'),
    url('../fonts/CharityMiles-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Charity Miles';
	src: local('GT Walsheim Bold'), local('GT-Walsheim-Bold'),
	url('../fonts/GT Walsheim Pro Bold Regular.woff2') format('woff2'),
	url('../fonts/GT Walsheim Pro Bold Regular.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
