.inputDate {
    margin-top: 0;
    display: flex;
    margin-right: 10px;
    > div {
        margin-right: 37px;
        margin-top: 0;
        margin-bottom: 0;
    }
    
    input {
        margin-right: 10px;
        border-bottom: 1px solid #1B1B1B;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
    }
    
    p {
        display: none;
    }
    
    > div {
        width: 175px;
        
        > div {
            &:before {
                border-bottom: none;
            }
            
            &:after {
                width: 108px;
            }
        }
    }
}

.modalFormInput{
    background: white !important;
    padding: 10px !important;
    border-radius: 10px !important;
    margin-top: 1rem;
    }

.middle {
    flex-direction: row; display: flex; align-items: center;
    .checkbox{
        margin:  15px 10px 15px 0px !important;
        padding: unset !important;
    }
}

.charity {
    display: flex;
    font-size: 18px;
    margin-top: 1rem;
    .typeCharity {
        display: flex;
        
        img {
            margin-left: 5px;
        }
    }
    
    > div:first-child {
        margin-right: 150px;
        
        .typeCharity {
            img {
                margin-left: 10px;
            }
        }
    }
    
    .inputData {
        display: flex;
        margin-top: 20px;
        
        input {
            padding-bottom: 4px;
        }
        
        &.charityName {
            align-items: flex-start;
            
            > div {
                display: flex;
                flex-direction: column;
                
                > div {
                    width: 120px;
                }
            }
        }
        
        img {
            width: 40px;
            margin-right: 6px;
        }
        
        .addAnother {
            color: #FE3E9A;
            font-size: 16px;
            line-height: 40px;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}

  
.paper {
    position: absolute;
    width: 100%;
    background: #c4c4c4;
    max-width: 700px;
    outline: none;
    box-shadow: 0px 15px 80px rgba(91, 109, 98, 1);
  
    .header {
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      .close {
        font-size: 30px !important;
        cursor: pointer;
        font-weight: 200;
        transform: rotate(45deg);
      }
      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 150%;
        color: #444444;
      }
    }
  
    .body {
      padding: 10px 20px;
      margin-top: 15px;
      min-height: 120px;
      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 125%;
        color: #000000;
      }
    }
    .footer {
      padding: 10px 20px;
      width: 100%;
      background-color: #f3f3f3;
      display: flex;
      align-items: center;
      .btnCancel {
        width: 100px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #444444;
        box-sizing: border-box;
        box-shadow: 0px 1px 3px rgba(91, 109, 98, 0.1);
        border-radius: 4px;
        margin: 10px;
        margin-left: auto;
        cursor: pointer;
        .txtCancel {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          text-align: center;
          color: #444444;
        }
      }
      .btnDelete {
        width: 100px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #fe3e9a;
        box-sizing: border-box;
        box-shadow: 0px 1px 3px rgba(91, 109, 98, 0.1);
        border-radius: 4px;
        cursor: pointer;
        .txtDelete {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;
          text-align: center;
          color: #fe3e9a;
        }
      }
    }
}