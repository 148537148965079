@import '~@mdi/font/css/materialdesignicons.min.css';
@import '~react-toastify/dist/ReactToastify.min.css';
@import './fonts';

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    background: none !important;
    box-shadow: 0 0 0 300px white inset !important;
}

.Toastify__toast {
    min-height: auto;
    border-radius: 4px;
}

h1, h2, h3, h4{
    margin: 0;
    line-height: 1.43;
}
img,
canvas{
    border: none;
    max-width: 100%;
}
a{
    outline: none;
}

p{
    margin: 0;
    line-height: 1.5;
}

.text-center{
    text-align: center;
}