@import "../../assets/scss/mixin";

.root{
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 20px;
    @include screen(sm) {
        padding: 30px;
    }
}
.container{
    width: 680px;
    max-width: 100%;
    @include screen(sm){
        margin-bottom: 150px;
    }
    .form {
        background-color: #fff;
        border-radius: 4px;
        padding: 30px;
        margin-bottom: 1rem;
        @include screen(sm, false) {
            max-width: 370px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .inputWrapUsername {
        margin-bottom: 10px;
        max-width: 100%;
        display: flex;
        align-items: center;
     
    }
    .validateMessage{
        color: red;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .btnNext{
        margin-right: 15px;
        @include screen(sm) {
            width: 180px;
        }
    }
    .txtAgain{
        color: rgb(0, 79, 182);
        cursor: pointer;
        text-decoration: underline;
    }
    .txtResetSuccess{
        color: rgb(2, 109, 46);
        font-size: 20px;
    }
    .txtLoginNow{
        color: rgb(0, 79, 182);
        cursor: pointer;
        text-decoration: underline;
    }
}

.title {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    @include screen(md) {
        height: 90px;
        flex-direction: column;
        margin-bottom: 35px;
        align-items: start;
    }
    .logo{
        width: 70px;
        display: inline-block;
        @include screen(sm) {
            width: 90px;
        }
        @include screen(md) {
            position: absolute;
            right: calc(100% + 15px);
            top: 50%;
            margin-top: -45px;
        }
    }
    h1{
        font-size: 19px;
        font-weight: 300;
        color: #444;
        @include screen(sm) {
            font-size: 40px;
        }
    }
    .subTitle{
        font-size: 15px;
        font-weight: 300;
        @include screen(sm){
            font-size: 23px;
            font-weight: 400;
        }
    }
}
