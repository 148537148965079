.box{
    .actions{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn{
        padding: 6px;
        height: auto;
        min-width: auto;
        font-size: 13px;
        color: #B6B6B6;
        letter-spacing: 0;
        &.active{
            color: #444;
            font-weight: 500;
        }
        &:disabled {
            color: #EBEBEB;
        }
    }
    
    .btnInvite {
        font-size: 15px;
        line-height: 19px;
        color: #FF50A4;
        &:hover {
            background: none;
        }
    }

    .iconBtn{
        padding: 0;
    }

}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h2{
        display: inline;
        font-size: 30px;
        font-weight: normal;
        line-height: 1;
        small{
            font-weight: 300;
            font-size: 21px;
        }
    }
    h4{
        font-size: 14px;
        letter-spacing: 0;
    }
}

.nameWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    .name{
        color: #5C8571;
        display: flex;
        align-items: center;
    }
    .actions{
        margin-right: -6px;
    }
}

.slider{
    .bar {
        margin-bottom: 5px;
        background-color: #E1F3EB;
        .inner {
            background-color: #4BA97C;
            height: 7px;
            width: percentage(139/323);
        }
    }
    .title{
        font-size: 13px;
        display: flex;
        justify-content: space-between;
    }
}

.noContent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
	a {
		text-decoration: none;
	}
}
.copiedClipboard {
	display: flex;
	justify-content: flex-end;
	bottom: 5px;
	right: 0;
	position: absolute;
	.message {
		align-items: center;
		display: flex;
		background: #444444;
		height: 32px;
		color: #fff;
		justify-content: center;
		width: 160px;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		font-size: 14px;
	}
}
.exportMenu{
    ul{
        padding: 4px 0;
    }
    .exportItem{
        font-size: 13px;
        padding: 2px 12px;
    }
}
.hidden {
    display: none;
}


