
// Media queries
$breakpoints: (
    sm: 768,
    md: 1024,
    lg: 1440
);
@mixin screen($breakpoint, $mobileFirst: true) {
    @if map-has-key($breakpoints, $breakpoint) {
        @if $mobileFirst {
            @media screen and (min-width: #{map-get($breakpoints, $breakpoint) + 'px'}) {
                @content;
            }
        }
        @else {
            @media screen and (max-width: #{(map-get($breakpoints, $breakpoint) - 1) + 'px'}) {
                @content;
            }
        }
    }

    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
    }
}
